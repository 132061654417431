'use strict'

import SearchCalendar from '@ui/Search/Calendar/component'

import type {
  Calendar as SearchCalendarType,
  NightsSelectionPlugin,
  RangeSelectionPlugin,
  PluginsConfig,
  FlexibleDatePlugin,
} from '@ui/Search/Calendar/component'

const calendarElementId = 'search_offers_checkInDate'

export default function (): SearchCalendarType | void {
  const inputEl = document.querySelector<HTMLElement>(`#${calendarElementId}`)

  if (!inputEl) {
    return
  }

  const setNightsFieldValue = (calendar: SearchCalendarType, value?: number): void => {
    if (calendar.element.hasAttribute('data-night-field')) {
      const nightFieldName = calendar.element.getAttribute('data-night-field')
      const nightFieldEl = document.querySelector<HTMLInputElement>(`[name="${nightFieldName}"]`)

      if (nightFieldEl && value && nightFieldEl.value !== value.toString()) {
        nightFieldEl.value = value.toString()
      }

      if (nightFieldEl && !value) {
        nightFieldEl.value = ''
      }
    }
  }

  const calendar = SearchCalendar(document.getElementById('search-form-calendar') as HTMLElement, {
    currentLocale: document.body.getAttribute('data-locale')?.split('_'),
    callbacks: {
      onChange: [
        (calendar) => {
          calendar.element.closest('.search-form__block')?.classList.add('dca-form__field--filled')
        },
      ],
      onOpen: [
        (calendar) => {
          calendar.element.closest('.search-form__block')?.classList.add('dca-form__field--focused')
        },
      ],
      onClose: [
        (calendar) => {
          calendar.element.closest('.search-form__block')?.classList.remove('dca-form__field--focused')
        },
      ],
    },
    pluginsConfig: {
      flexibleDate: (calendar) => ({
        onValueChange: (value) => {
          if (calendar.element.hasAttribute('data-flexible-date-field')) {
            const flexibleDatedName = calendar.element.getAttribute('data-flexible-date-field')
            const flexibleDateFieldEl = document.querySelector<HTMLInputElement>(`[name="${flexibleDatedName}"]`)

            if (flexibleDateFieldEl) {
              flexibleDateFieldEl.checked = value === 1
            }
          }
        },
      }),
      nightsSelection: (calendar) => ({
        onValueChange: (value) => {
          setNightsFieldValue(calendar, value)
        },
      }),
      rangeSelection: (calendar) => ({
        ajaxUrl: calendar.input.getAttribute('data-url-get-available-checkout-dates'),
        ajaxData: {
          productId: calendar.input.getAttribute('data-product-id') as string,
        },
        onValueChange: (nights) => {
          setNightsFieldValue(calendar, nights)
        },
      }),
    } as {
      flexibleDate: PluginsConfig<FlexibleDatePlugin['config']>
      rangeSelection: PluginsConfig<RangeSelectionPlugin['config']>
      nightsSelection: PluginsConfig<NightsSelectionPlugin['config']>
    },
  })

  return calendar
}
